import RichText from 'Custom/RichText';
import Link from 'node_modules/next/link';

const FAQ_USERS_1_LIST = [
  'dolor que no cesa en la parte inferior de la pierna;',
  'dolor de pecho intenso u opresión en el pecho;',
  'dificultad repentina para respirar, fuerte dolor de pecho o tos con sangre;',
  'síntomas de una reacción alérgica grave, como hinchazón de la cara, la lengua o la garganta; dificultad para tragar o para respirar;',
  'dolores de cabeza intensos y repentinos distintos de los dolores de cabeza habituales;',
  'debilidad o adormecimiento en el brazo o la pierna, o dificultad para hablar;',
  'ceguera repentina parcial o total;',
  'color amarillento de la piel o la parte blanca de los ojos, especialmente acompañado de fiebre, cansancio, pérdida del apetito, orina de color oscuro o heces de color claro;',
  'dolor intenso, hinchazón o sensibilidad en la parte inferior del estómago (abdomen);',
  'bulto en el seno;',
  'problemas para dormir, falta de energía, cansancio o mucha tristeza;',
  'sangrado menstrual abundante;',
  'sensación de que el implante puede haberse roto o doblado mientras se encontraba en el brazo.',
];

export const FAQ_USERS_2_LIST = [
  'Aprepitant',
  'Barbitúricos',
  'Bosentán',
  'Carbamazepina',
  'Felbamato',
  'Griseofulvina',
  'Oxcarbazepina',
  'Fenitoína',
  'Rifampicina',
  'Hierba de San Juan',
  'Topiramato',
  'Medicamentos para el VIH',
  'Medicamentos para tratar el virus de la hepatitis C',
];

const FAQ_USERS_3_LIST_1 = [
  'Está embarazada o cree que podría estarlo',
  'Tiene o tuvo coágulos de sangre graves, como coágulos de sangre en las piernas (trombosis venosa profunda), los pulmones (embolia pulmonar), los ojos (ceguera parcial o total), el corazón (infarto de miocardio) o el cerebro (accidente cerebrovascular)',
  'Tiene una enfermedad hepática o un tumor hepático',
  'Tiene sangrado vaginal sin motivo aparente',
  'Tiene o ha tenido cáncer de mama u otro tipo de cáncer sensible a la progestina (una hormona femenina)',
  'Es alérgica a algún componente de NEXPLANON',
];

const FAQ_USERS_3_LIST_2 = [
  'Diabetes',
  'Colesterol o triglicéridos elevados',
  'Dolores de cabeza',
  'Problemas vesiculares o renales',
  'Antecedentes de estado depresivo',
  'Presión arterial alta',
  'Alergia a los medicamentos que se utilizan para anestesiar (anestésicos) o a los medicamentos que se utilizan para limpiar la piel (antisépticos). Estos medicamentos se utilizarán al colocar o extraer el implante del brazo.',
];

const FAQ_EXPLORING_QUESTION_1 = {
  title: '¿NEXPLANON es un anticonceptivo de acción prolongada?',
  content: (
    <RichText>
      <p>
        Sí, NEXPLANON es un implante anticonceptivo pequeño, delgado y flexible
        que le ofrece 3 años de prevención continua del embarazo. El profesional
        de la salud lo coloca en un lugar discreto debajo la piel, en el lado
        interno del brazo no dominante.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_2 = {
  title: '¿NEXPLANON es un dispositivo intrauterino?',
  content: (
    <RichText>
      <p>
        No, no es un dispositivo intrauterino, ya que se coloca en el brazo, y
        no en el útero. Sin embargo, al igual que un dispositivo intrauterino,
        es una opción anticonceptiva de acción prolongada, ya que dura 3 años.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_3 = {
  title: '¿qué tamaño tiene NEXPLANON?',
  content: (
    <RichText>
      <p>
        NEXPLANON es pequeño. Mide 4 centímetros (1.6 pulgadas) de largo y tiene
        aproximadamente el tamaño de una cerilla.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_4 = {
  title: '¿cómo funciona NEXPLANON?',
  content: (
    <RichText>
      <p>
        NEXPLANON es un implante anticonceptivo liberador de hormonas para el
        uso en mujeres a fin de prevenir el embarazo durante un máximo de 3
        años.
      </p>
      <p>
        NEXPLANON previene el embarazo de varias maneras. La más importante
        consiste en interrumpir la liberación de óvulos desde el ovario.
        NEXPLANON también hace más espeso el moco cervical, y este cambio puede
        evitar que el espermatozoide llegue al óvulo.
      </p>
      <p>NEXPLANON también modifica las paredes del útero.</p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_5 = {
  title: '¿NEXPLANON contiene estrógeno?',
  content: (
    <RichText>
      <p>No, NEXPLANON no contiene estrógeno.</p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_6 = {
  title: '¿NEXPLANON funciona tan bien como la píldora?',
  content: `NEXPLANON tiene una eficacia mayor del 99 % (menos de 1 embarazo por cada 100 mujeres que usaron NEXPLANON durante 1 año) en la prevención del embarazo, además de que no es necesario acordarse de tomarlo todos los días. NEXPLANON es uno de los`,
  linkLabel: 'métodos anticonceptivos más eficaces disponibles',
  link: '/effectiveness/',
};

const FAQ_EXPLORING_QUESTION_6_FAQ = {
  title: FAQ_EXPLORING_QUESTION_6.title,
  content: (
    <RichText>
      <p>
        {`${FAQ_EXPLORING_QUESTION_6.content} `}
        <Link
          data-design-category="content_link_vd"
          data-design-type="clickAction"
          data-design-action="Link"
          data-design-label={FAQ_EXPLORING_QUESTION_6.linkLabel}
          data-design-nori_id="Accordion"
          data-design-module_name="acerca de NEXPLANON"
          data-design-module_no="02"
          data-design-element_no="06"
          href={FAQ_EXPLORING_QUESTION_6.link}
        >
          {FAQ_EXPLORING_QUESTION_6.linkLabel}
        </Link>
        .
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_6_HOME = {
  title: FAQ_EXPLORING_QUESTION_6.title,
  content: (
    <RichText>
      <p>
        {`${FAQ_EXPLORING_QUESTION_6.content} `}
        <Link
          data-design-category="content_link_vd"
          data-design-type="clickAction"
          data-design-action="Link"
          data-design-label={FAQ_EXPLORING_QUESTION_6.linkLabel}
          data-design-nori_id="Accordion"
          data-design-module_name="Preguntas para discutir con su médico"
          data-design-module_no="06"
          data-design-element_no="05"
          href={FAQ_EXPLORING_QUESTION_6.link}
        >
          {FAQ_EXPLORING_QUESTION_6.linkLabel}
        </Link>
        .
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_7 = {
  title: '¿qué pasa si quiero usar NEXPLANON por más de 3 años?',
  content: (
    <RichText>
      <p>
        El implante NEXPLANON debe extraerse después de 3 años. Su profesional
        de la salud puede insertarle un nuevo implante debajo de la piel después
        de extraer el anterior si usted decide continuar utilizando NEXPLANON
        como método anticonceptivo.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_8 = {
  title:
    '¿qué sucede si cambio de opinión y deseo dejar de usar NEXPLANON antes de los 3 años?',
  content: (
    <RichText>
      <p>
        Su profesional de la salud puede extraer el implante en cualquier
        momento dentro del período de 3 años. Puede quedar embarazada a partir
        de la primera semana después de la extracción del implante. Si no desea
        quedar embarazada después de que su profesional de la salud extraiga el
        implante NEXPLANON, debe comenzar a utilizar otro método anticonceptivo,
        como condones, de inmediato.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_9 = {
  title: '¿NEXPLANON influirá en mis períodos?',
  content: (
    <RichText>
      <p>
        El efecto secundario más frecuente de NEXPLANON es un cambio en su
        patrón normal de sangrado menstrual. En estudios, 1 de cada 10 mujeres
        dejó de usar el implante por un cambio desfavorable en su patrón de
        sangrado. El período puede durar más o menos, o es posible que
        desaparezca por completo. El tiempo entre períodos puede variar, y
        también puede experimentar manchado entre los períodos.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_10 = {
  title:
    '¿en qué momento debería planear la inserción de NEXPLANON en el brazo?',
  content: (
    <RichText>
      <p>
        Su profesional de la salud la ayudará a determinar cuándo debe{' '}
        <Link
          data-design-category="content_link_vd"
          data-design-type="clickAction"
          data-design-action="Link"
          data-design-label="colocarse NEXPLANON"
          data-design-nori_id="Accordion"
          data-design-module_name="acerca de NEXPLANON"
          data-design-module_no="02"
          data-design-element_no="11"
          href="/insertion/"
        >
          colocarse NEXPLANON
        </Link>
        . El momento dependerá de si actualmente está usando un método
        anticonceptivo y cuál es. No debe usar NEXPLANON si está embarazada o
        cree que puede estarlo.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_11 = {
  title: '¿cómo es la extracción de NEXPLANON?',
  content: (
    <RichText>
      <p>
        El profesional de la salud le puede extraer NEXPLANON en cualquier
        momento durante el período de 3 años; la extracción debe hacerse antes
        del final del tercer año. Para obtener más información sobre cómo se
        extrae NEXPLANON,{' '}
        <Link
          data-design-category="content_link_vd"
          data-design-type="clickAction"
          data-design-action="Link"
          data-design-label="haga clic aquí."
          data-design-nori_id="Accordion"
          data-design-module_name="acerca de NEXPLANON"
          data-design-module_no="02"
          data-design-element_no="11"
          href="/removal/"
        >
          haga clic aquí
        </Link>
        .
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_12 = {
  title: '¿quién no debe usar NEXPLANON?',
  content: (
    <RichText>
      <p>
        <Link
          data-design-category="content_link_vd"
          data-design-type="clickAction"
          data-design-action="Link"
          data-design-label="No use NEXPLANON"
          data-design-nori_id="Accordion"
          data-design-module_name="usuarias de NEXPLANON"
          data-design-module_no="03"
          data-design-element_no="03"
          href="/es-us/efectos-secundarios/"
        >
          No use NEXPLANON
        </Link>{' '}
        en los siguientes casos:
      </p>
      {FAQ_USERS_3_LIST_1 && (
        <ul className="tightened">
          {FAQ_USERS_3_LIST_1.map((item, i) => (
            <li key={`faq-users-3-1-${i}`}>{item}</li>
          ))}
        </ul>
      )}
      <p>
        Informe a su profesional de la salud si tiene o ha tenido alguna de las
        afecciones antes mencionadas. Su profesional de la salud puede sugerirle
        un método anticonceptivo diferente.
      </p>
      <p>
        Además, hable con su profesional de la salud acerca del uso de NEXPLANON
        si tiene:
      </p>
      {FAQ_USERS_3_LIST_2 && (
        <ul className="tightened">
          {FAQ_USERS_3_LIST_2.map((item, i) => (
            <li key={`faq-users-3-1-${i}`}>{item}</li>
          ))}
        </ul>
      )}
    </RichText>
  ),
};

export const FAQ_EXPLORING_QUESTIONS = [
  FAQ_EXPLORING_QUESTION_1,
  FAQ_EXPLORING_QUESTION_2,
  FAQ_EXPLORING_QUESTION_3,
  FAQ_EXPLORING_QUESTION_4,
  FAQ_EXPLORING_QUESTION_5,
  FAQ_EXPLORING_QUESTION_6_FAQ,
  FAQ_EXPLORING_QUESTION_7,
  FAQ_EXPLORING_QUESTION_8,
  FAQ_EXPLORING_QUESTION_9,
  FAQ_EXPLORING_QUESTION_10,
  FAQ_EXPLORING_QUESTION_11,
];

export const FAQ_HOME_QUESTIONS = [
  FAQ_EXPLORING_QUESTION_4,
  FAQ_EXPLORING_QUESTION_6_HOME,
  FAQ_EXPLORING_QUESTION_2,
  FAQ_EXPLORING_QUESTION_9,
  FAQ_EXPLORING_QUESTION_12,
  FAQ_EXPLORING_QUESTION_11,
];

export const FAQ_NEXPLANON_USERS = [
  {
    title: '¿cuándo debo llamar a mi profesional de la salud?',
    content: (
      <RichText>
        <p>Llame de inmediato a su profesional de la salud si tiene:</p>
        {FAQ_USERS_1_LIST && (
          <ul className="tightened">
            {FAQ_USERS_1_LIST.map((item, i) => (
              <li key={`faq-users-${i}`}>{item}</li>
            ))}
          </ul>
        )}
      </RichText>
    ),
  },
  {
    title: '¿qué medicamentos pueden interactuar con NEXPLANON?',
    content: (
      <RichText>
        <p>
          Informe a su profesional de la salud sobre todos los medicamentos que
          toma, incluidos los medicamentos recetados y los medicamentos de venta
          libre, las vitaminas y los suplementos a base de hierbas. Algunos
          medicamentos pueden reducir la eficacia de NEXPLANON, entre ellos:
        </p>
        {FAQ_USERS_2_LIST && (
          <ul className="tightened">
            {FAQ_USERS_2_LIST.map((item, i) => (
              <li key={`faq-users-2-${i}`}>{item}</li>
            ))}
          </ul>
        )}
        <p>
          Consulte con su profesional de la salud si no está segura de que el
          medicamento que toma figura en la lista anterior.
        </p>
        <p>
          Si está tomando medicamentos o productos a base de hierbas que pueden
          reducir la eficacia de NEXPLANON, usted y su médico pueden decidir
          suspender el uso de NEXPLANON. En ese caso, usted deberá usar un
          anticonceptivo no hormonal adicional. Debido a que el efecto de otro
          medicamento en NEXPLANON puede durar hasta 28 días después de
          suspender el medicamento, es necesario utilizar el anticonceptivo no
          hormonal adicional durante ese tiempo.
        </p>
        <p>
          Cuando esté utilizando NEXPLANON, informe a todos sus profesionales de
          la salud que tiene NEXPLANON implantado en el brazo.
        </p>
      </RichText>
    ),
  },
  {
    title: FAQ_EXPLORING_QUESTION_12.title,
    content: FAQ_EXPLORING_QUESTION_12.content,
  },
  {
    title: '¿qué sucede si quedo embarazada mientras utilizo NEXPLANON?',
    content: (
      <RichText>
        <p>
          Debe visitar de inmediato a su profesional de la salud si cree que
          puede estar embarazada. Es importante extraer NEXPLANON y asegurarse
          de que el embarazo no sea ectópico (fuera del útero). Según la
          experiencia con otros anticonceptivos hormonales, no es probable que
          NEXPLANON provoque defectos de nacimiento.
        </p>
      </RichText>
    ),
  },
  {
    title: '¿puedo usar NEXPLANON mientras estoy amamantando?',
    id: 'breastfeeding',
    content: (
      <RichText>
        <p>
          Si está amamantando a su bebé, puede usar NEXPLANON si transcurrieron
          4 semanas desde el parto. Una pequeña cantidad de la hormona que
          contiene NEXPLANON se excreta en su leche materna. Se ha estudiado la
          salud de un número reducido de niños lactantes hasta los 3 años de
          edad cuyas madres utilizaban el implante. No se observó ningún efecto
          en el crecimiento y desarrollo de los niños. Si usted está amamantando
          y desea usar NEXPLANON, hable con su profesional de la salud para
          obtener más información.
        </p>
      </RichText>
    ),
  },
  {
    title: '¿NEXPLANON estará cubierto por el seguro?',
    content: (
      <RichText>
        <p>
          Es posible que pueda obtener NEXPLANON gratis, en el marco de la Ley
          del Cuidado de Salud a Bajo Precio. Consulte con su plan de seguro
          para obtener información sobre los{' '}
          <Link
            data-design-category="content_link_vd"
            data-design-type="clickAction"
            data-design-action="Link"
            data-design-label="costos"
            data-design-nori_id="Accordion"
            data-design-module_name="usuarias de NEXPLANON"
            data-design-module_no="03"
            data-design-element_no="06"
            href="/cost/"
          >
            costos
          </Link>{' '}
          y la{' '}
          <Link
            data-design-category="content_link_vd"
            data-design-type="clickAction"
            data-design-action="Link"
            data-design-label="cobertura"
            data-design-nori_id="Accordion"
            data-design-module_name="usuarias de NEXPLANON"
            data-design-module_no="03"
            data-design-element_no="06"
            href="/insurance-coverage/"
          >
            cobertura
          </Link>
          . Es posible que deba pagar un copago, un deducible u otros cargos.
        </p>
      </RichText>
    ),
  },
];
